<template>
  <div v-if="isInitiated">
    <ModalDeleteSubscription
      v-if="showDeleteSubscription"
      :showModal="showDeleteSubscription"
      :subscription="selectedSubscription"
      :onClickCancel="hideModal"
    />

    <ui-page-hero id="id_title_channel" :title="'Subscriptions'" />

    <div>
      <MonthYearSelector
        @monthYearSelected="monthYearSelected"
        :isLoadingLeft="isLoadingLeft"
        :isLoadingRight="isLoadingRight"
        :showMonthSelector="true"
        :showYearSelector="true"
      />

      <div class="tabs is-boxed">
        <ul>
          <li
            :class="{
              'is-active': subscriptionsPageState.selectedStatusId === 0,
            }"
          >
            <a @click="setSelectedStatusId(0)"><span>All</span></a>
          </li>

          <li
            :class="{
              'is-active': subscriptionsPageState.selectedStatusId === 1,
            }"
          >
            <a @click="setSelectedStatusId(1)"><span>Free trial</span></a>
          </li>

          <li
            :class="{
              'is-active': subscriptionsPageState.selectedStatusId === 2,
            }"
          >
            <a @click="setSelectedStatusId(2)"><span>Recurring</span></a>
          </li>

          <li
            :class="{
              'is-active': subscriptionsPageState.selectedStatusId === 3,
            }"
          >
            <a @click="setSelectedStatusId(3)"><span>Cancelled</span></a>
          </li>
        </ul>
      </div>

      <div class="navbar-menu">
        <div class="navbar-start">
          <span class="is-small">
            <div class="control">
              <label class="radio">
                <input
                  type="radio"
                  name="billable"
                  v-model="showBillable"
                  :value="0"
                  :checked="showBillable === 0"
                />
                All
              </label>
              <label class="radio">
                <input
                  type="radio"
                  name="billable"
                  v-model="showBillable"
                  :value="1"
                  :checked="showBillable === 1"
                />
                Billable only
              </label>
              <label class="radio">
                <input
                  type="radio"
                  name="billable"
                  v-model="showBillable"
                  :value="2"
                  :checked="showBillable === 2"
                />
                Non billable only
              </label>
            </div>
          </span>

          <span
            class="select is-small has-margin-left"
            :class="{ 'is-loading': isLoadingPackages }"
          >
            <select
              v-model="subscriptionsPageState.packageId"
              @change="getSubscriptions"
            >
              <option
                :value="0"
                :selcted="subscriptionsPageState.packageId === 0"
              >
                All packages
              </option>
              <option
                v-for="(pck, index) in pckgs"
                :value="pck.Id"
                :selected="pck.Id === subscriptionsPageState.packageId"
                :key="index"
              >
                {{ pck.Name }}
              </option>
            </select>
          </span>
        </div>

        <div class="navbar-end">
          <div class="field has-addons">
            <input
              type="text"
              v-model="subscriptionsPageState.searchTerm"
              v-on:keyup.13="searchSubscriptions"
              class="input is-small"
              placeholder="Search subscriptions"
            />
            <button
              class="button is-primary is-small"
              :class="{ 'is-loading': isSearching }"
              @click="searchSubscriptions"
            >
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>

      <table class="table is-fullwidth is-striped is-hoverable">
        <thead>
          <th>Location</th>
          <th>Package</th>
          <th class="has-text-right" width="100">Period</th>
          <th class="has-text-right">Price</th>
          <th class="has-text-right" width="100">Billable</th>
          <th class="has-text-right" width="100">Free trial</th>
          <th class="has-text-right" width="100">Recurring</th>
          <!-- <th class="has-text-right" width="100">Cancelled</th> -->
          <th>&nbsp;</th>
        </thead>
        <tbody>
          <tr
            v-for="subscription in filteredSubscriptions"
            :key="subscription.Id"
          >
            <td>
              <a @click="goToLocation(subscription.LocationId)">{{
                subscription.LocationName
              }}</a>
            </td>
            <td>
              <div>{{ subscription.PackageName }}</div>
            </td>
            <td class="has-text-right">
              {{ subscription.Month | getMonthName }} {{ subscription.Year }}
            </td>
            <td class="has-text-right">
              {{ subscription.Price | toCurrency }}
            </td>
            <td class="has-text-right">
              <a
                @click="setNotBillable(subscription)"
                v-if="subscription.SendInvoice"
              >
                <span class="has-icon">
                  <span class="icon">
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      class="has-text-success"
                    />
                  </span>
                </span>
              </a>

              <a
                @click="setBillable(subscription)"
                v-if="!subscription.SendInvoice"
              >
                <span class="has-icon">
                  <span class="icon">
                    <font-awesome-icon
                      :icon="['fas', 'times']"
                      class="has-text-danger"
                    />
                  </span>
                </span>
              </a>

              <!-- <span class="has-icon">
                <span class="icon">
                  <font-awesome-icon
                    v-if="subscription.SendInvoice"
                    :icon="['fas', 'check']"
                    class="has-text-success"
                  />
                  <font-awesome-icon
                    v-if="!subscription.SendInvoice"
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  />
                </span>
              </span> -->
            </td>
            <td class="has-text-right">
              <span class="has-icon">
                <span class="icon">
                  <font-awesome-icon
                    v-if="subscription.IsTrial"
                    :icon="['fas', 'check']"
                    class="has-text-success"
                  />
                  <font-awesome-icon
                    v-if="!subscription.IsTrial"
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  />
                </span>
              </span>
            </td>
            <td class="has-text-right">
              <span class="has-icon">
                <span class="icon">
                  <font-awesome-icon
                    v-if="subscription.IsRecurring"
                    :icon="['fas', 'check']"
                    class="has-text-success"
                  />
                  <font-awesome-icon
                    v-if="!subscription.IsRecurring"
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  />
                </span>
              </span>
            </td>
            <!-- <td class="has-text-right">
              <span class="has-icon">
                <span class="icon">
                  <font-awesome-icon
                    v-if="subscription.IsCancelled"
                    :icon="['fas', 'check']"
                    class="has-text-success"
                  />
                  <font-awesome-icon
                    v-if="!subscription.IsCancelled"
                    :icon="['fas', 'times']"
                    class="has-text-danger"
                  />
                </span>
              </span>
            </td> -->
            <td width="75">
              <div class="field is-grouped">
                <p class="control">
                  <a @click="goToSubscription(subscription)" class="is-small">
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'edit']" />
                    </span>
                  </a>
                </p>
                <p class="control">
                  <span class="has-text-danger">
                    <a
                      @click.exact="setShowDeleteSubscription(subscription)"
                      @click.ctrl="instantDelete(subscription)"
                      class="has-text-danger"
                    >
                      <span class="icon">
                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                      </span>
                    </a>
                  </span>
                </p>
              </div>

              <!-- <a @click.stop="extendSubscription(subscription)"
                ><i class="fas fa-redo"></i
              ></a> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/eventbus/event-bus'
import { mapActions, mapState, mapMutations } from 'vuex'
import subscriptionProvider from '@/providers/subscription'

export default {
  components: {
    MonthYearSelector: () => import('@/components/UI/MonthYearSelector'),
    //SearchBar: () => import('@/components/SearchBar'),
    ModalDeleteSubscription: () =>
      import('@/components/Subscriptions/ModalDeleteSubscription'),
  },

  data() {
    return {
      bottom: false,
      channelId: Number(this.$route.params.channelId),
      isLoadingLeft: false,
      isLoadingRight: false,
      isSearching: false,
      isLoadingPackages: false,
      locationId: 0,

      packageId: 0,
      selectedSubscription: null,
      showDeleteSubscription: false,

      showBillable: 0,
    }
  },
  computed: {
    ...mapState(['isInitiated']),
    ...mapState('packageStore', ['pckgs']),
    ...mapState('subscriptionStore', ['subscriptionsPageState']),

    filteredSubscriptions() {
      let self = this
      let subscriptions = self.subscriptionsPageState.subscriptions

      if (this.subscriptionsPageState.selectedStatusId === 0) {
        subscriptions = subscriptions.filter((s) => !s.IsCancelled)
      }

      if (this.subscriptionsPageState.onlyCancelled) {
        if (subscriptions && subscriptions.length > 0) {
          subscriptions = subscriptions.filter(function (s) {
            return s.IsCancelled
          })
        }
      }

      if (this.subscriptionsPageState.onlyFreeTrial) {
        if (subscriptions && subscriptions.length > 0) {
          subscriptions = subscriptions.filter(function (s) {
            return s.IsTrial
          })
        }
      }

      if (this.subscriptionsPageState.onlyRecurring) {
        if (subscriptions && subscriptions.length > 0) {
          subscriptions = subscriptions.filter(function (s) {
            return s.IsRecurring
          })
        }
      }

      if (this.showBillable === 1) {
        subscriptions = subscriptions.filter((s) => s.SendInvoice)
      }

      if (this.showBillable === 2) {
        subscriptions = subscriptions.filter((s) => !s.SendInvoice)
      }

      return subscriptions
    },
  },

  created() {
    if (this.pckgs.length === 0) {
      this.getAllPackages(this.channelId)
    }
    this.getSubscriptions()
  },

  methods: {
    ...mapActions('packageStore', ['getAllPackages']),
    ...mapActions('subscriptionStore', [
      'deleteSubscription',
      'editSubscription',
    ]),
    ...mapMutations('subscriptionStore', [
      'setSubscription',
      'setSubscriptionsPageState',
    ]),

    getSubscriptions() {
      if (!this.isSearching) {
        this.isSearching = true

        subscriptionProvider.methods
          .getSubscriptions(
            this.channelId,
            this.locationId,
            this.subscriptionsPageState.month,
            this.subscriptionsPageState.year,
            this.subscriptionsPageState.packageId,
            this.subscriptionsPageState.searchTerm
          )
          .then((response) => {
            if (response.status === 200) {
              //self.subscriptionsPageState.morePages = response.data.MorePages
              this.subscriptionsPageState.subscriptions = response.data

              this.setSubscriptionsPageState(this.subscriptionsPageState)
            }
          })
          .then(() => {
            this.isLoadingLeft = false
            this.isLoadingRight = false
          })
          .finally(() => {
            this.isSearching = false
          })
      }
    },

    searchSubscriptions() {
      this.page = 1
      this.reachedEnd = false
      this.bottom = false
      //this.subscriptionsPageState.searchTerm = searchTerm
      this.subscriptionsPageState.subscriptions = []

      this.setSubscriptionsPageState(this.subscriptionsPageState)

      this.getSubscriptions()
    },

    monthYearSelected(data) {
      let self = this

      self.subscriptionsPageState.month = data.month
      self.subscriptionsPageState.year = data.year

      self.isLoadingLeft = data.direction === 'left'
      self.isLoadingRight = data.direction === 'right'

      this.page = 1
      this.reachedEnd = false
      this.subscriptionsPageState.subscriptions = []

      this.setSubscriptionsPageState(this.subscriptionsPageState)

      this.getSubscriptions()
    },

    setSelectedStatusId(status) {
      this.subscriptionsPageState.selectedStatusId = status

      if (status === 0) {
        this.subscriptionsPageState.onlyFreeTrial = false
        this.subscriptionsPageState.onlyRecurring = false
        this.subscriptionsPageState.onlyCancelled = false
      }

      if (status === 1) {
        this.subscriptionsPageState.onlyFreeTrial = true
        this.subscriptionsPageState.onlyRecurring = false
        this.subscriptionsPageState.onlyCancelled = false
      }

      if (status === 2) {
        this.subscriptionsPageState.onlyFreeTrial = false
        this.subscriptionsPageState.onlyRecurring = true
        this.subscriptionsPageState.onlyCancelled = false
      }

      if (status === 3) {
        this.subscriptionsPageState.onlyFreeTrial = false
        this.subscriptionsPageState.onlyRecurring = false
        this.subscriptionsPageState.onlyCancelled = true
      }

      this.setSubscriptionsPageState(this.subscriptionsPageState)
    },

    goToSubscription(subscription) {
      this.setSubscription(subscription)

      this.$router.push({
        name: `subscriptions-detail`,
        params: { channelId: this.channelId, subscriptionId: subscription.Id },
      })
    },

    instantDelete(subscription) {
      let self = this

      self
        .deleteSubscription(subscription)
        .then((response) => {
          if (response.status === 204) {
            // Send message to display toast on screen
            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Subscription deleted!',
            })
          }
        })
        .finally(() => {
          self.isDeleting = false
        })
    },

    setNotBillable(subscription) {
      subscription.SendInvoice = false
      this.editSubscription(subscription)
    },

    setBillable(subscription) {
      subscription.SendInvoice = true
      this.editSubscription(subscription)
    },

    saveSubscription(value) {
      subscription.methods.saveSubscription(value).then((response) => {})
    },

    cancelDeleteSubscription() {
      this.subscriptionToDelete = null
    },

    extendSubscription(value) {
      subscription.methods.extendSubscription(value).then((response) => {
        ////console.info(response.data)
      })
    },

    setShowDeleteSubscription(subscription) {
      this.showDeleteSubscription = true
      this.selectedSubscription = subscription
    },

    hideModal() {
      this.showDeleteSubscription = false
      this.selectedSubscription = null
    },

    goToLocation(locationId) {
      this.$router.push({
        name: `locations-detail`,
        params: { channelId: this.channelId, locationId: locationId },
      })
    },
  },
}
</script>
